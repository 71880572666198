@import '_vars';

#company {
    padding: 10rem 0;
    margin-bottom: 5rem;
    background-color: $body-bg-color-alt;

    .card {
        text-align: center;
        display: inline-block;
        padding: 2.5rem 0;
        
        img {
            width: 30%;
        }
    }

    .card-body {
        font-size: 0.85rem;
        margin-top: 10px;
        font-weight: 900;
    }

    .btn {
        margin-top: 3rem;
    }
}

