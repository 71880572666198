/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500&family=Prompt:wght@500;600;700;800&display=swap');
@import "_vars";

body {
    background-color: $body-bg-color;
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: subpixel-antialiased;
    margin-top: 7.5rem;
    overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Poppins', sans-serif;
}

.btn-primary {
    padding: 1rem 3.5rem;
    border-radius: 8rem;
    border: none;
    background-color: lighten($lime-green-color, 7.5);
    text-align: center;
    font-size: 0.9rem;
    letter-spacing: 0.05rem;
    word-spacing: 0.10rem;
    color: $button-text-color !important;
    font-weight: 700;

    &:hover, &:active {
        background-color: $lime-green-color;
    }
}

.subheading {
    display: inline-block;
    padding-bottom: 5px;
    position: relative;
    font-weight: 900;
    font-size: 1.5rem;
    font-family: 'Prompt', sans-serif !important;
    margin-bottom: 2.5rem;

    &::before {
        content: "";
        position: absolute;
        width: 20%;
        height: 1px;
        bottom: -15px;
        left: 40%;
        border-bottom: 4px solid $lime-green-color;
    }
}

.tagline {
    font-weight: 300;
    font-size: 3rem;
    color: $base-text-color;

    b {
        color: $highlight-text-color;
        font-weight: 600;
    }
}

.subtext {
    display: block;
    margin-top: 1.4rem;
    font-size: 1.3rem;
    font-weight: 200;
    color: $sub-text-color;
}

.top-margin {
    margin: 10rem 0;
}

/* Breakpoint SM */
@media (max-width: 767px)
{
    .top-margin {
        margin: 2rem 0;
    }

    .subheading {
        margin-bottom: 2.5rem;
    }
}