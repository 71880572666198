@import "_vars";

$duration: .2s;
$distance: 8px;
$easeOutBack: cubic-bezier(0.175, 0.885, 0.320, 1.275);

.navbar {
    background-color: $white-color;
    padding: 2rem !important;
}

.navbar-brand > h2 {
    font-weight: 900;
    margin-left: 1rem;
    font-size: 2.05rem;
    word-spacing: 0rem;
    font-family: 'Prompt', sans-serif;
    color: $base-text-color;
    margin-bottom: 0.75rem;
    
    b {
        color: $highlight-text-color;
    }
}

.nav-item {
    margin: 0 1.15rem;
    
    a {
        font-weight: 700 !important;
        font-family: 'Prompt', sans-serif;
        color: $base-text-color !important;
        font-size: 0.85rem;
        letter-spacing: .10rem;
        position: relative;
      
        &:before, &:after {
            content: '';
            position: absolute;
            bottom: -10px;
            left: 10px; 
            right: 10px;
            height: 4px;
            background-color: $highlight-text-color;
        }

        &:before {
            opacity: 0;
            transform: translateY(- $distance);
            transition: transform 0s $easeOutBack, opacity 0s;
        }
        &:after {
            opacity: 0;
            transform: translateY($distance/2);
            transition: transform $duration $easeOutBack, opacity $duration;
        }

        &:hover,
        &:focus,
        &:active {
            &:before,
            &:after {
                opacity: 1;
                transform: translateY(0);
            }
            &:before {
                transition: transform $duration $easeOutBack, opacity $duration;
            }
            &:after {
                transition: transform 0s $duration $easeOutBack, opacity 0s $duration;
            }
        }
    }
}