@import '_vars';

#learn-more {
    padding: 10rem 0;
    background-color: $body-bg-color-alt;

    img {
        opacity: 0.65;
    }

    .btn {
        margin-top: 3rem;
    }

    .subtext {
        margin-top: 1.4rem;
        display: inline-block;
    }
}