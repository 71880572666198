@import '_vars';

#footer {
    padding-bottom: 3rem;
    h5 {
        font-size: 1.35rem;
        font-family: 'Prompt', sans-serif;
        font-weight: 900;
        display: inline;
    }
    
    a {
        margin: 15px;
        text-decoration: none;
        
        i {
            color: $lime-green-color;
            font-size: 1.3rem;
        
            span {
                color: $lime-green-color;
                font-weight: 400;
                margin-left: 10px;
                font-size: 0.95rem;
                font-family: 'Poppins', sans-serif;
            }
        }
    }

    #copyright {
        color: $sub-text-color;
        font-size: 0.9rem;
    }

    @media (max-width: 767px) {
        span {
            display: inline-block;
            margin-top: 1rem;
            margin-left: 5px;
        }
    }
}